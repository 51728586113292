@import '../../variables';
@import './banner-text';
@import './banner-fw-landscape';
@import './banner-narrow';
@import './banner-asymmetric-box';
@import './banner-text-plp';
@import './box-4-products';
@import './banner-related-board';
@import './box-2-products';
@import './box-3-products';
@import './mini-slider-box-all-fields';
@import './product-tile';
@import './banner-one-row-board';
@import './banner-vertical-board';
@import './bright-promotion-banner';
@import './products-wall';
@import 'mixins';
@import 'hero-banner';
@import 'category-module';
@import 'seo-text-plp';
@import 'service-module';
@import 'service-carousel';
@import 'full-width';
@import '../../main/module-colors';

/* stylelint-disable no-descending-specificity */
.overflow-hidden {
  overflow: hidden !important;
}

.text-small {
  font-size: 1.2rem;
}

.text-large {
  font-size: 1.6rem;
}

cms-wall-slider,
.cms-wall-skeleton-container {
  padding: 0.8rem 0.75rem 0 0.75rem;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-auto-rows: auto;
  @screen md {
    grid-template-columns: repeat(3, 2fr);
  }
  @screen lg {
    padding: 1.2rem;
    padding-bottom: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  swiper-slide {
    display: none;
  }
  .skeleton {
    display: none;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    &:nth-child(even) {
        padding-left: 0.953rem;
      }
    &:nth-child(odd) {
      padding-right: 0.953rem;
    }
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      display: flex;
    }
    &:nth-of-type(-n+2) {
      padding-bottom: 3rem;
    }

    @screen md {
      &:nth-of-type(5),
      &:nth-of-type(6) {
        display: flex;
      }
    }

    @screen lg {
      padding-left: 1.44rem;
      padding-right: 1.44rem;
      display: flex;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
      
      &:nth-of-type(-n+6) {
        padding-bottom: 4rem;
      }
    }

    &-img {
      display: flex;
      background-color: $white-smoke;
      height: 9rem;
      width: 100%;
      max-width: 18rem;
    }
    &-text {
      display: flex;
      width: 50%;
      margin: 0.8rem 0 0.4rem;
      height: 1.9rem;
      background-color: $white-smoke;
      &:nth-child(3) {
        width: 40%;
        margin: 0 0 0.8rem;
      }
    }
    &-cta {
      height: 1.5rem;
      width: 8rem;
      background-color: $white-smoke;
      border-radius: 0;
    }
    &-title {
      margin-bottom: 2.5rem;
      display: flex;
      width: 20rem;
      height: 1.4rem;
      top: 0;
      position: absolute;
      background-color: $white-smoke;
    }
  }
}

sgh-brand-wall {
  display: flex;
  flex-direction: column;
  margin-left: 4rem;
  margin-right: 4rem;

  @screen mdr {
    margin: 0 1.5rem;
  }

  .skeleton {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    column-gap: 0.8rem;
    row-gap: 0.8rem;
    width: 100%;

    @screen lgr {
      grid-template-columns: auto auto;

      .skeleton-box:nth-child(-n + 7),
      .skeleton-box:last-child {
        display: block;
      }
    }

    @screen md {
      .skeleton-box:nth-child(-n + 17),
      .skeleton-box:last-child {
        display: block;
      }
    }

    &-box {
      display: none;
      height: 6.1rem;
      @include skeleton-animation;
    }

    &-title {
      @include skeleton-animation;
      width: 15rem;
      height: 1.8rem;
      margin-bottom: 2.4rem;

      @screen mdr {
        width: 11.5rem;
        height: 2.1rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}

sgh-cms-trends-anchor-buttons {
  height: 13.4rem;
  background-color: $alabaster;
  display: flex;
}

.cms-row-carousel-board,
.cms-related-board-banner,
.cms-banner-vertical-board,
.box-4-products,
.cms-mini-slider-box-all-fields,
.cms-wall-slider {
  @include swiper-pagination;
  @include swiper-arrows;

  .swiper-pagination-bullet {
    &:first-child:last-child {
      display: none;
    }
  }
}

.cms-text-bfb-banner {
  .swiper-pagination {
    margin-top: 2.4rem;
  }
}

.cms-text-module {
  &__title,
  &__text {
    margin-top: 1.5rem;

    > p:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__actions {
    margin-top: 4rem;

    &:first-child {
      margin-top: 0;
    }
  }
}

.sgh-my-account {
  .cms-text {
    &-module {
      &__title,
      &__text {
        margin-top: 0;

        &:first-child {
          padding-bottom: 0;
        }
      }

      &__actions {
        margin-top: 0.8rem;
      }
    }

    &-align-center {
      padding-top: 0;
    }
  }
}

.pageContentCodice {
  .introMainTitle,
  .wrapMainIntro_title {
    @include actaHeadlineFontTitle();
    letter-spacing: 0.014rem;
    margin: 2.4rem 0;
  }

  .wrapMainIntro_subtitle {
    @include primaryFont();
  }
}

strong {
  font-size: inherit;
}

p a {
  color: inherit;

  &:hover {
    color: inherit;
    opacity: 0.8;
  }
}

.cms-related-board-banner {
  .banners > a {
    align-items: center;
    padding: 0.2rem;
  }

  .banners > a > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sgh-row {
  .cms-text-bfb-banner {
    padding: 4rem 24rem 6.4rem 24rem;

    @screen lgr {
      padding: 1.6rem 3rem;
    }

    h3 {
      margin-bottom: 1.6rem;
      color: $mine-shaft;
      @include actaHeadlineFontTitle();
    }

    .sgh-swiper,
    .sgh-grid-container {
      margin-top: 4rem;

      @screen lgr {
        margin-top: 3.2rem;
      }

      h4 {
        line-height: 2.1rem;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
        color: $black;
      }

      .button-container {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        margin-top: 1.6rem;
        line-height: 2.1rem;

        button {
          height: 2.1rem;
          line-height: 2;
          margin-bottom: 3.8rem;
        }
      }
    }

    .swiper-pagination {
      display: flex;
      width: 27.4rem;
      margin: auto;

      @screen md {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      &:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
      }

      &:last-child {
        border-radius: 0 0.5rem 0.5rem 0;
      }

      width: 100%;
      margin: 0 !important;
      margin-top: 3.6rem;
      height: 0.5rem;
      background-color: $mine-shaft;
      opacity: 0.1;
      border-radius: 0;
      border: none;

      &-active {
        background-color: $mine-shaft;
        opacity: 1;
        border-radius: 0.5rem;
      }
    }

    .swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
      scroll-snap-align: center center;
    }
  }
}
.brand-name {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 4rem;
  padding-bottom: 2.4rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  z-index: 9999;
}

%acta-headline {
  @include actaHeadlineFont();
  font-weight: 400;
  letter-spacing: 0.014rem;
}

.acta-headline {
  @extend %acta-headline;
}

%acta-headline-h1 {
  @extend %acta-headline;
  font-size: 4rem;
  line-height: 4rem;
  letter-spacing: 0.02rem;

  @media screen and (max-width: 768px) {
    font-size: 3.2rem;
    line-height: 3.2rem;
    letter-spacing: 0.016rem;
  }
}

.acta-headline-h1 {
 @extend %acta-headline-h1;
}


%acta-headline-h2 {
  @extend %acta-headline;
  font-size: 2.8rem;
  line-height: 2.8rem;
}

.acta-headline-h2 {
  @extend %acta-headline-h2;
}

.acta-headline-h3 {
  @extend %acta-headline;
  font-size: 2.8rem;
  line-height: 2.8rem;

  @media screen and (max-width: 768px) {
    font-size: 2.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.012rem;
  }
}

%acta-headline-h3 {
  @extend %acta-headline-h3;
}


.promoRow__text {
  .promoRow__h3 {
    @extend %acta-headline-h3;
  }
}

#contentPage {
  #heroRX {
    .warpText {
      .firstTitle {
        @extend %acta-headline-h1;
      }
    }
  }

  .introMainTitle2 {
    @extend %acta-headline-h2;
  }

  .copyShadesBox,
  .limitedEditionBlock_wrapText,
  .bgColrTextBox {
    h2 {
      @extend %acta-headline-h2;
    }
  }

  .inStoreExperience_textWrap {
    h2 {
      @extend %acta-headline-h2;
    }
  }
}

#ct_header {
  .ct_sm__view__header__text {
    h2:first-of-type {
      @include actaHeadlineFontTitle();
    }
  }
}

#giftGuide2022 {
  .wrapIntroText,
  .wrapIntroText_new {
    h1 {
      @extend %acta-headline-h1;
    }
  }

  #iconic,
  #trend,
  #shopWM {
    h2 {
      @extend %acta-headline-h2;
    }
  }

  #sunnySliderBlock {
    .sunnySliderTextBlock {
      h2 {
        @extend %acta-headline-h2;
      }
    }
  }

  #otherStories,
  #makeItSpecialSection {
    h3 {
      @extend %acta-headline-h2;
    }
  }

  #giftCards {
    .perfectGiftWrapText3 {
      h3 {
        @extend %acta-headline-h2;
      }
    }
  }

  #perfectGiftIdeas {
    .textWrap_personas2 {
      h2 {
        @extend %acta-headline-h2;
      }
    }

    #luxury,
    #iconic,
    #trend {
      .titleShopBy_2 {
        @extend %acta-headline-h2;
      }
    }
  }
}

#prescriptionLp {
  #heroBannerTop {
    .heroTitle {
      @extend %acta-headline-h1;
    }
  }
  #prSunglasses,
  #brandedLenses {
    .generalTitle {
      @extend %acta-headline-h2;
    }
  }
  #visionNeedSection {
    .visionNeed_title {
      @extend %acta-headline-h2;
    }
  }
  #ourLencesBlock {
    .ourLences_title {
      @extend %acta-headline-h2;
    }
  }
  #bestSellersBlock {
    .bestSellers_title {
      @extend %acta-headline-h2;
    }
  }
  #signUpSgh {
    .signUpSgh_title {
      @extend %acta-headline-h2;
    }
  }
}

#sunnyServices {
  #bannerHeader {
    h1 {
      @extend %acta-headline-h1;
    }
  }

  .wrapTextIntroSider {
    h3 {
      @extend %acta-headline-h1;
    }
  }

  .blockSection {
    h2 {
      @extend %acta-headline-h2;
    }
  }
  .wrapPopUp {
    overscroll-behavior: contain;
    top: 10% !important;
    @media only screen and (min-width: 1025px) {
      margin-top: 2% !important;
      top: 0 !important;
    }
  }
  @media only screen and (min-width: 1025px) {
    z-index: 1 !important;
  }
}

#careers2022 {
  #heroBanner {
    .wrapHeroText {
      h1 {
        @extend %acta-headline-h1;
      }
    }
  }

  #whoWeAre,
  #ourCulture,
  #startsHere,
  #stayInTheKnow {
    h2 {
      @extend %acta-headline-h2;
    }
  }

  #inStoreRoles,
  #stayInTheKnow {
    .generalTitle {
      @extend %acta-headline-h2;
    }
  }
}

#ct_wow_module {
  .ct_wow {
    .ct_cos {
      margin-bottom: 4rem;
      margin-top: 0;
    }
  }
}

#ct_slides {
  .slick-list {
    .slick-track {
      .ct_sm__view__content__item {
        padding-top: 0;
      }
    }
  }
}

#ct_rtr {
  .ct_rtr__container {
    margin-bottom: 4rem;
    @screen mdr {
      margin-top: 0;
    }
  }
}

.content-visibility {
  content-visibility: auto;
  contain-intrinsic-size: auto 1rem;
}
